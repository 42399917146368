<template>
  <div class="container-fluid">
    <div class="row mb-3">
      <div class="col my-auto">
        <h5 class="text-primary fw-bold mb-0">
          Clients
          <span v-if="!loading && pagination">({{ pagination.total }})</span>
        </h5>
      </div>
      <div class="col-auto my-auto ms-auto">
        <button class="btn btn-outline-primary btn-sm" @click="openAddNewClient">
          <i class="far fa-plus me-2"></i> Add Client
        </button>
      </div>
      <div class="col-auto mx-auto">
        <router-link to="/clients/import" class="btn btn-outline-primary btn-sm">
          <i class="far fa-upload me-2"></i>Import Clients
        </router-link>
      </div>
    </div>
    <div class="card min-h-screen">
      <div class="card-header">
        <div class="row">
          <div class="col-md-5 my-auto align-items-center">
            <input
              type="text"
              v-model="search"
              class="form-control form-control-sm"
              autocomplete="offington"
              placeholder="Search by id, name, email, phone, dob (dd-mm-yyyy) or tags"
              @input="searchClients"
            />
          </div>
          <div class="col-auto my-auto">
            <button class="btn btn-outline-primary btn-sm" @click="clearSearch">
              <i class="far fa-times"></i>
            </button>
          </div>

          <div class="col-auto my-auto ms-auto">
            <toggle-button v-model="showInactive" :color="'#03006b'" />
            <label class="custom-control-label ms-2 text-sm text-capitalize">
              Show Inactive Clients
            </label>
          </div>
          <div class="col-auto">
            <div class="dropdown">
              <button
                class="btn btn-light btn-sm dropdown-toggle"
                type="button"
                id="sortingDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="far fa-sort me-2"></i>
                <span class="text-capitalize">{{ sorting | formatLabel }}</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="sortingDropdown">
                <button
                  class="dropdown-item"
                  type="button"
                  @click="sortBy('id')"
                >
                  ID
                </button>
                <button
                  class="dropdown-item"
                  type="button"
                  @click="sortBy('email')"
                >
                  Email
                </button>
                <button
                  class="dropdown-item"
                  type="button"
                  @click="sortBy('phone')"
                >
                  Phone
                </button>
                <button
                  class="dropdown-item"
                  type="button"
                  @click="sortBy('created_at')"
                >
                  Created At
                </button>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <button
              class="btn btn-outline-dark btn-sm me-2"
              :class="sortDirection == 'asc' ? 'active' : ''"
              @click="sortDirection = 'asc'"
            >
              <i class="far fa-sort-size-up"></i>
            </button>
            <button
              class="btn btn-outline-dark btn-sm"
              :class="sortDirection == 'desc' ? 'active' : ''"
              @click="sortDirection = 'desc'"
            >
              <i class="far fa-sort-size-down"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-hover clientList">
          <thead>
            <tr>
              <th scope="col" class="sortable" @click="tableHeaderSortBy('id')">
                #
                <i
                  v-if="sorting === 'id'"
                  :class="[
                    'fad',
                    sortDirection === 'asc'
                      ? 'fa-sort-size-up'
                      : 'fa-sort-size-down',
                  ]"
                />
                <i v-else class="far fa-sort-size-down-alt opacity4" />
              </th>
              <th
                scope="col"
                class="sortable"
                @click="tableHeaderSortBy('first_name')"
              >
                First Name
                <i
                  v-if="sorting === 'first_name'"
                  :class="[
                    'fad',
                    sortDirection === 'asc'
                      ? 'fa-sort-size-up'
                      : 'fa-sort-size-down',
                  ]"
                />
                <i v-else class="far fa-sort-size-down-alt opacity4" />
              </th>
              <th
                scope="col"
                class="sortable"
                @click="tableHeaderSortBy('last_name')"
              >
                Last Name
                <i
                  v-if="sorting === 'last_name'"
                  :class="[
                    'fad',
                    sortDirection === 'asc'
                      ? 'fa-sort-size-up'
                      : 'fa-sort-size-down',
                  ]"
                />
                <i v-else class="far fa-sort-size-down-alt opacity4" />
              </th>
              <th
                scope="col"
                class="sortable"
                @click="tableHeaderSortBy('email')"
              >
                Email
                <i
                  v-if="sorting === 'email'"
                  :class="[
                    'fad',
                    sortDirection === 'asc'
                      ? 'fa-sort-size-up'
                      : 'fa-sort-size-down',
                  ]"
                />
                <i v-else class="far fa-sort-size-down-alt opacity4" />
              </th>
              <th scope="col">Phone</th>
              <th
                scope="col"
                class="sortable"
                @click="tableHeaderSortBy('became_client')"
              >
                Became Client
                <i
                  v-if="sorting === 'became_client'"
                  :class="[
                    'fad',
                    sortDirection === 'asc'
                      ? 'fa-sort-size-up'
                      : 'fa-sort-size-down',
                  ]"
                />
                <i v-else class="far fa-sort-size-down-alt opacity4" />
              </th>
              <th scope="col">Tags</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="loading">
              <td colspan="7">
                <loading class="my-4"></loading>
              </td>
            </tr>
            <tr
              v-else
              v-for="c in clients"
              :key="c.id"
              @click="redirectToClient(c.id)"
              class="cursor-pointer"
            >
              <th scope="row">
                #{{ c.id }}
                <span
                  v-if="c.status === 'contact'"
                  class="badge bg-primary ms-1 small"
                  >Contact</span
                >
                <span v-if="!c.active" class="badge bg-warning ms-1 small"
                  >Inactive</span
                >
              </th>
              <td class="text-capitalize">{{ c.first_name }}</td>
              <td class="text-capitalize">{{ c.last_name }}</td>
              <td>{{ c.email }}</td>
              <td>{{ c.phone }}</td>
              <td>{{ c.became_client | formatDate }}</td>
              <td
                style="
                  max-width: 150px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                v-tooltip:bottom="getTagTooltip(c)"
              >
                <span
                  v-for="tag in c.tags"
                  v-bind:key="tag.id"
                  class="badge bg-info ms-1 small"
                >
                  {{ tag.name.en }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <!--  -->
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col my-auto">
                <button
                  class="btn btn-sm btn-light"
                  @click="fetchClients(pagination.first_page_url)"
                  :disabled="!pagination.first_page_url"
                >
                  First Page
                </button>

                <button
                  class="btn btn-sm btn-light ms-3"
                  @click="fetchClients(pagination.prev_page_url)"
                  :disabled="!pagination.prev_page_url"
                >
                  Previous
                </button>
              </div>
              <div class="col my-auto text-center">
                <p class="mb-0">
                  Page
                  {{ pagination.current_page ? pagination.current_page : 1 }} of
                  {{ pagination.last_page ? pagination.last_page : 1 }}
                </p>
              </div>
              <div class="col my-auto text-end">
                <button
                  class="btn btn-sm btn-light cursor-pointer me-3"
                  @click="fetchClients(pagination.next_page_url)"
                  :disabled="!pagination.next_page_url"
                >
                  Next
                </button>

                <button
                  class="btn btn-sm btn-light cursor-pointer"
                  @click="fetchClients(pagination.last_page_url)"
                  :disabled="!pagination.last_page_url"
                >
                  Last Page
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--  -->
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/views/components/loader/Loading";

export default {
  props: ["user"],
  data() {
    return {
      loading: true,
      clients: [],
      pagination: {},
      search: "",
      sorting: null,
      filtering: null,
      sortDirection: "desc",
      showAddClient: false,
      showInactive: false,
      debounce: null,
      tableHeaderSort: {
        id: null,
        first_name: null,
        last_name: null,
        email: null,
      },
    };
  },
  methods: {
    tableHeaderSortBy(type) {
      const current = this.tableHeaderSort[type];
      let next = {
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        became_client: null,
      };

      if (this.sortDirection === "asc") {
        this.sortDirection = "desc";
      } else {
        this.sortDirection = "asc";
      }
      if (current === null) {
        next[type] = "asc";
      } else if (current === "asc") {
        next[type] = "desc";
      } else {
        next[type] = null;
      }
      this.sorting = type;

      this.fetchClients();
    },
    openAddNewClient() {
      this.$EventBus.$emit("showAddNewClient");
    },
    sortBy(field) {
      this.sorting = field;
      this.fetchClients();
    },
    filterBy(field) {
      this.filtering = field;
      this.fetchClients();
    },
    updateQueryStringParameter(uri, key, value) {
      var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      var separator = uri.indexOf("?") !== -1 ? "&" : "?";
      if (uri.match(re)) {
        return uri.replace(re, "$1" + key + "=" + value + "$2");
      } else {
        return uri + separator + key + "=" + value;
      }
    },
    clearSearch() {
      this.search = "";
      this.fetchClients();
    },
    searchClients(term) {
      this.fetchClients();
    },
    fetchClients(paginationUrl) {
      this.loading = true;
      var url = paginationUrl
        ? paginationUrl
        : process.env.VUE_APP_API_URL + "/clients/fetch-index";

      if (this.sorting) {
        url = this.updateQueryStringParameter(url, "sort", this.sorting);
      }

      if (this.sortDirection) {
        url = this.updateQueryStringParameter(
          url,
          "sort_direction",
          this.sortDirection
        );
      }

      if (this.filtering) {
        url = this.updateQueryStringParameter(url, "filter", this.filtering);
      }

      if (this.search) {
        url = this.updateQueryStringParameter(url, "search", this.search);
      }

      if (this.showInactive) {
        url = this.updateQueryStringParameter(
          url,
          "show_inactive",
          this.showInactive
        );
      }

      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.$axios.get(url).then(({ data }) => {
          this.pagination = data;
          this.clients = data.data;
          this.loading = false;
        });
      }, 600);
    },
    redirectToClient(id) {
      this.$router.push("/clients/" + id);
      // window.location = "/clients/" + id;
    },
    getTagTooltip(c) {
      var retStr = "";
      c.tags.forEach((tag) => {
        retStr += tag.name.en + ", ";
      });
      if (c.tags.length === 0) {
        retStr = "No tags";
      } else {
        //remove trailing comma
        retStr = retStr.substring(0, retStr.length - 2);
      }

      return retStr;
    },
  },
  filters: {
    formatLabel(str) {
      if (!str) {
        return "Sorting";
      } else {
        return str.replace(/_/g, " ");
      }
    },
    formatDate(date) {
      return date ? moment(date).format("Do MMM YYYY") : "Not Set";
    },
  },
  created() {
    this.fetchClients();
  },
  mounted() {
    const _this = this;
    this.$EventBus.$on("clientAdded", function () {
      _this.fetchClients();
    });
  },
  watch: {
    sortDirection: {
      handler: function (val) {
        this.fetchClients();
      },
    },
    showInactive: {
      handler: function (val) {
        this.fetchClients();
      },
    },
  },
  components: {
    Loading,
  },
};
</script>

<style>
.clientList th.sortable .opacity4 {
  opacity: 0.4;
}
.clientList th.sortable {
  cursor: ns-resize;
}
</style>
